import { AppContext, LoadingBackdrop, sleep } from '@hkexpressairwayslimited/ui';
import {
  ComponentPropsContext,
  LayoutServicePageState,
  SitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { handleEditorFastRefresh } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import cacache from 'cacache';
import { layoutDataCachePath } from 'lib/cache/layout-data';
import { ssgCachePath, ssgPathsCacheKey } from 'lib/cache/ssg';
import { SitecorePageProps } from 'lib/page-props';
import { sitecorePagePropsFactory } from 'lib/page-props-factory';
import { envConfig } from 'lib/services/env';
import { sitemapFetcher } from 'lib/sitemap-fetcher';
import { GetStaticPaths, GetStaticProps } from 'next';
import { Params } from 'next/dist/shared/lib/router/utils/route-matcher';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { performance } from 'perf_hooks';
import { useEffect, useMemo, useState } from 'react';
import Layout, { RouteFields } from 'src/Layout';
import NotFound from 'src/NotFound';
import { componentBuilder } from 'temp/componentBuilder';
export interface AnalyticIBEDetail {
  event?: string;
  page?: {
    name?: string;
    language?: string;
    platform?: string;
  };
}

export const adobeDataLayerPush = (data: AnalyticIBEDetail) => {
  const w: any = window;
  if (w?.adobeDataLayer === undefined) {
    return;
  } else {
    data && w.adobeDataLayer?.push(data);
    console.log(data, 'dataLayer');
  }
};

const getAnalyticPath = (pathName: string = '') => {
  const languageList = envConfig.languageList;
  const path = pathName.slice(1);
  const pathArr = path.split('/') || [];
  const languageLocale = pathArr[0] || '';
  const isInclude = languageList.includes(languageLocale);
  const language = isInclude
    ? languageLocale.length === 2
      ? languageLocale
      : languageLocale.split('-')?.[0] || 'en'
    : 'en';
  return {
    language,
    pathName: isInclude ? pathArr.slice(1).join('/') || '/' : path,
  };
};

const SitecorePage = ({
  notFound,
  componentProps,
  layoutData,
  headLinks,
  breadcrumbs,
  blurImageList,
}: SitecorePageProps): JSX.Element => {
  const router = useRouter();
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [appReady, setAppReady] = useState(false);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [isPushAdobeDataLayer, setIsPushAdobeDataLayer] = useState(false);
  useEffect(() => {
    // Since Sitecore editors do not support Fast Refresh, need to refresh editor chromes after Fast Refresh finished
    handleEditorFastRefresh();
    setAppReady(true);
    console.info('layoutData', layoutData);
  }, []);

  useEffect(() => {
    // adobe analytics
    if (scriptLoaded && !isPushAdobeDataLayer) {
      const { language, pathName } = getAnalyticPath(window?.location.pathname);
      adobeDataLayerPush({
        event: 'pageview',
        page: {
          name: pathName,
          language: language,
          platform: 'web',
        },
      });
      setIsPushAdobeDataLayer(true);
    }
  }, [scriptLoaded]);

  useEffect(() => {
    const routeEventStart = () => {
      setIsPageLoading(true);
    };
    const routeEventEnd = () => {
      setIsPageLoading(false);
    };
    router.events.on('routeChangeStart', routeEventStart);
    router.events.on('routeChangeComplete', routeEventEnd);
    router.events.on('routeChangeError', routeEventEnd);
    return () => {
      router.events.off('routeChangeStart', routeEventStart);
      router.events.off('routeChangeComplete', routeEventEnd);
      router.events.off('routeChangeError', routeEventEnd);
    };
  }, [router]);

  const isEditing = layoutData.sitecore.context.pageEditing;
  const isPreview = layoutData.sitecore.context.pageState === LayoutServicePageState.Preview;
  const appContextValue = useMemo(
    () => ({
      editMode: layoutData.sitecore.context.editMode as string | undefined,
      isInEditor: isEditing || false,
      isPreview,
      breadcrumbs,
      blurImageList,
    }),
    [layoutData.sitecore.context.editMode, isEditing, isPreview, breadcrumbs]
  );

  if (notFound || !layoutData?.sitecore?.route) {
    // Shouldn't hit this (as long as 'notFound' is being returned below), but just to be safe
    return <NotFound />;
  }

  return (
    <>
      {envConfig.adobeAnalyticScript && (
        <Script
          src={envConfig.adobeAnalyticScript}
          strategy={'afterInteractive'}
          onReady={() => {
            setScriptLoaded(true);
          }}
        />
      )}
      <ComponentPropsContext value={componentProps}>
        <SitecoreContext
          componentFactory={componentBuilder.getComponentFactory({ isEditing })}
          layoutData={layoutData}
        >
          <AppContext.Provider value={appContextValue}>
            {/*
          Sitecore Pages supports component rendering to avoid refreshing the entire page during component editing.
          If you are using Experience Editor only, this logic can be removed, Layout can be left.
        */}
            <LoadingBackdrop isOpen={!isEditing && !isPreview && (isPageLoading || !appReady)} />
            <Layout layoutData={layoutData} headLinks={headLinks} />
          </AppContext.Provider>
        </SitecoreContext>
      </ComponentPropsContext>
    </>
  );
};

// This function gets called at build and export time to determine
// pages for SSG ("paths", as tokenized array).
export const getStaticPaths: GetStaticPaths = async (context) => {
  // Fallback, along with revalidate in getStaticProps (below),
  // enables Incremental Static Regeneration. This allows us to
  // leave certain (or all) paths empty if desired and static pages
  // will be generated on request (development mode in this example).
  // Alternatively, the entire sitemap could be pre-rendered
  // ahead of time (non-development mode in this example).
  // See https://nextjs.org/docs/basic-features/data-fetching/incremental-static-regeneration

  let paths: {
    params: Params;
    locale?: string;
  }[] = [];
  let fallback: boolean | 'blocking' = 'blocking';

  if (process.env.NODE_ENV !== 'development' && !process.env.DISABLE_SSG_FETCH) {
    try {
      // Note: Next.js runs export in production mode
      paths = await sitemapFetcher.fetch(context);
    } catch (error) {
      console.log('Error occurred while fetching static paths');
      console.log(error);
    }
    fallback = false;
  }

  paths.sort((a, b) => {
    const aPath = a.params.path.join('/');
    const bPath = b.params.path.join('/');

    return aPath.localeCompare(bPath);
  });

  console.log('Generating paths', JSON.stringify(paths));

  const cachePaths = paths.map((path) => `/${path.locale}/${path.params.path.join('/')}`);
  await cacache
    .put(ssgCachePath, ssgPathsCacheKey, Buffer.from(JSON.stringify(cachePaths)))
    .catch(() => {});

  return {
    paths,
    fallback,
  };
};

// This function gets called at build time on server-side.
// It may be called again, on a serverless function, if
// revalidation (or fallback) is enabled and a new request comes in.
export const getStaticProps: GetStaticProps = async (context: any) => {
  const itemPath =
    typeof context.params?.path === 'string'
      ? context.params?.path
      : context.params?.path
        ? `/${context.params?.path.join('/')}`
        : '';
  const layoutDataCacheKey = `/${context.locale}${itemPath || '/'}`;
  const ssgPaths = (await cacache
    .get(ssgCachePath, ssgPathsCacheKey)
    .then((buffer) => JSON.parse(buffer.data.toString()))
    .catch(() => [])) as string[];

  console.log('Generating props', layoutDataCacheKey, itemPath);
  const props = await sitecorePagePropsFactory.create(context);
  const breadcrumbSegments = itemPath.split('/');
  const breadcrumbObjList = [];

  console.log('breadcrumbSegments', breadcrumbSegments);
  for (let index = breadcrumbSegments.length - 1; index > 0; index--) {
    const breadcrumbPath = breadcrumbSegments.slice(0, index).join('/');
    const breadcrumbCacheKey = `/${context.locale}${breadcrumbPath || '/'}`;
    const isSsgPath = ssgPaths.some((path) => path === breadcrumbCacheKey);

    let waited = 0;
    console.log('breadcrumbCacheKey', breadcrumbCacheKey);
    while (waited <= 60000) {
      const startTime = performance.now();
      try {
        const layoutCache: SitecorePageProps = await cacache
          .get(layoutDataCachePath, breadcrumbCacheKey)
          .then((buffer) => JSON.parse(buffer.data.toString()));
        const pageTitle = (layoutCache.layoutData.sitecore?.route?.fields as RouteFields).pageTitle
          ?.value;

        if (pageTitle) {
          const breadcrumbObj = { href: breadcrumbCacheKey, title: pageTitle };

          breadcrumbObjList.unshift(breadcrumbObj);
          console.log('breadcrumbObj', breadcrumbObj);
        }
        break;
      } catch {
        if (isSsgPath) {
          await sleep(1000);
          const endTime = performance.now();
          waited += endTime - startTime;

          console.log(
            'Waiting for breadcrumb cache',
            waited,
            breadcrumbCacheKey,
            itemPath,
            breadcrumbSegments
          );
        } else {
          break;
        }
      }
    }
  }
  props.breadcrumbs = breadcrumbObjList;
  await cacache
    .put(layoutDataCachePath, layoutDataCacheKey, Buffer.from(JSON.stringify(props)))
    .catch(() => {});

  return {
    props,
    revalidate: false,
    notFound: props.notFound, // Returns custom 404 page with a status code of 404 when true
  };
};

export default SitecorePage;
